import React, { useContext, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import requestClient from '../../utils/requestClient';
import { months } from '../../utils/dateUtils';
import Tooltip from '../../components/Elements/Tooltip';
import Histogram from '../../components/Elements/Reporting/Histogram';
import Loader from '../../components/Elements/Loader';
import ArticleLists from '../../components/Sections/ArticleLists';
import WorkshopCard from '../../components/Elements/Cards/WorkshopCard';
import { pillarMapper, pillarNameMapper } from '../../utils/pillarMapper';
import { CurrentUser } from '../../App';
import {ChevronRightIcon} from '@heroicons/react/24/solid'

const Dashboard = () => {
    const {user} = useContext(CurrentUser)
    const now = new Date();
    const month2 = new Date()
    const month3 = new Date()

    month2.setMonth(now.getMonth() - 1)
    month3.setMonth(now.getMonth() - 2)

    const monthNames = [
        months[now.getMonth()],
        months[month2.getMonth()],
        months[month3.getMonth()],
    ]

    const [data, setData] = useState(null);
    const [recommendedArticles, setRecommendedArticles] = useState(null);
    const [latestArticles, setLatestArticles] = useState(null);
    const [sentimentArticles, setSentimentArticles] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(1);
    const [selfReflection, setSelfReflection] = useState({});

    const updateSelfReflection = (id, value) => {
        let newSelfReflection = { ...selfReflection };
        newSelfReflection[id] = value
        setSelfReflection(newSelfReflection);
    }

    const getSelfReflectionData = async (month) => {
        if (!(month in selfReflection)) {
            const response = await requestClient().get(`/api/v2/self-reflection?month=${month}`)
            updateSelfReflection(month, response.data.data)
        }

        setCurrentMonth(month)
    }

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get('/api/v2/dashboard/')

            // If we have questionaires then lets redirect the user
            if (response.data.data.questionnaires.length > 0) {
                const questionnaires = response.data.data.questionnaires
                window.location.href = `/questionnaire/${questionnaires[0]}`
                return
            }

            let recommendedArticles = {}
            let sentimentArticles = {}
            let latestContent = {}

            if(response.data.data.content.length > 0){
                recommendedArticles['resources'] = {
                    title: null,
                    link: null,
                    articles: []
                }
            
                response.data.data.content.map(article => {
                    recommendedArticles['resources'].articles.push(article)
                })
            }

            if(response.data.data.latest_content.length > 0){
                latestContent['resources'] = {
                    title: null,
                    link: null,
                    articles: []
                }
            
                response.data.data.latest_content.map(article => {
                    latestContent['resources'].articles.push(article)
                })
            }

            // if(response.data.data.employer_content.length > 0){
            //     recommendedArticles['company'] = {
            //         title: 'Company Resources',
            //         link: null,
            //         articles: []
            //     }
            
            //     response.data.data.employer_content.map(article => {
            //         recommendedArticles['company'].articles.push(article)
            //     })
            // }

            if(response.data.data.sentiment_content.length > 0){
                sentimentArticles['sentiment'] = {
                    title: null,
                    link: null,
                    articles: []
                }
            
                response.data.data.sentiment_content.map(article => {
                    sentimentArticles['sentiment'].articles.push(article)
                })
            }

            setRecommendedArticles(recommendedArticles);
            setSentimentArticles(sentimentArticles);
            setLatestArticles(latestContent);
            setData(response.data.data);
        }
        getData();
    }, []);

    useEffect(() => {
        async function getSelfReflection() {
            await getSelfReflectionData(currentMonth)
        }
        getSelfReflection();
    }, []);

    const ScorecardItems = ({selfReflection}) => {
        return (
            selfReflection.map((data, index) => {
                const pillar = pillarNameMapper(data.name)

                return (
                    <div key={index}>
                        <a href={'/workshops?pillar=' + data.name_code} title={`View ${ pillar.title} Workshops`}
                            className='hover:opacity-80 relative group'
                        >
                            <ChevronRightIcon className={'transition-all absolute top-2 right-2 h-[20px] group-hover:right-0'} />
                            <h4 className='mb-2'>{pillar.title}</h4>
                            <Histogram histogramData={[data]} />
                        </a>
                    </div>
                )
            })
        )
    };

    return (data ?
        <main>
            <section className='mb-8'>
                <div className="container grid grid-cols-12 gap-8">
                    <div className="col-span-12 bg-white p-8 lg:col-span-8">
                        <div className="flex mb-4 gap-6 items-center  flex-col lg:flex-row lg:justify-between">
                            <div className='flex items-center gap-2'>
                                <h3>My Wellbeing Scorecard</h3>
                                <Tooltip text="Track how you are performing against the pilars of Mind, Body, Career, Surrounding, Relationships and Money." />
                            </div>
                            <div className="flex items-center">
                                <button className={`transition-all w-[40px] h-[40px] flex items-center justify-center text-black bg-grey-light rounded-full hover:bg-purple hover:text-white ${currentMonth === monthNames.length ? 'opacity-50' : ''}`} disabled={currentMonth === monthNames.length} onClick={() => getSelfReflectionData(currentMonth + 1)}>&lt;</button>
                                <span className="font-bold mx-6">{monthNames[currentMonth - 1]}</span>
                                <button className={`transition-all w-[40px] h-[40px] flex items-center justify-center text-black bg-grey-light rounded-full hover:bg-purple hover:text-white ${currentMonth === 1 ? 'opacity-50' : ''}`} disabled={currentMonth === 1} onClick={() => getSelfReflectionData(currentMonth - 1)}>&gt;</button>
                            </div>
                        </div>
                        <p className={'mb-2 text-sm'}>Your wellbeing scorecard helps guide you towards which of the Six Pillars to focus on this month.</p>
                        <div className='grid lg:grid-cols-2 gap-4'>
                            {currentMonth in selfReflection ? (<ScorecardItems selfReflection={selfReflection[currentMonth]} />) : null}
                        </div>
                    </div>

                    <aside className="col-span-12 bg-purple-light text-white p-8 lg:col-span-4">
                        <WorkshopCard />
                    </aside>
                </div>
            </section>
            {recommendedArticles ? (<section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between gap-4 flex-col lg:flex-row border-b pb-8'>
                        <h2>Your recommended content</h2>
                        {/* <Link to="/resources" className='btn btn-invert w-full flex justify-center lg:w-auto'>
                            View All
                        </Link> */}
                    </div>
                    <ArticleLists data={recommendedArticles} articlesPerRow={3} />
                </div>
            </section>) : null}

            {sentimentArticles ? (<section className="">
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between gap-4 flex-col lg:flex-row border-b pb-8'>
                        <h2>Recommended based on your weekly check-in</h2>
                        {/* <Link to="/resources/sentiment" className='btn btn-invert w-full flex justify-center lg:w-auto'>
                            View All
                        </Link> */}
                    </div>
                    <ArticleLists data={sentimentArticles} articlesPerRow={3} />
                </div>
            </section>) : null}

            {latestArticles ? (<section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between gap-4 flex-col lg:flex-row border-b pb-8'>
                        <h2>Latest resources</h2>
                        <Link to="/resources" className='btn btn-invert w-full flex justify-center lg:w-auto'>
                            View All
                        </Link>
                    </div>
                    <ArticleLists data={latestArticles} articlesPerRow={3} />
                </div>
            </section>) : null}
        </main>
        : <Loader className="my-8" text="Loading your dashboard" />);
};

export default Dashboard;
