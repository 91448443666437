import React, { useState, useEffect, } from 'react';
import { useSearchParams } from "react-router-dom";
import requestClient from '../../utils/requestClient';
import {resourceMapper} from '../../utils/resourceMapper';
import ArticleLists from '../../components/Sections/ArticleLists';
import { pillarMap } from '../../utils/pillarMapper';
import FormSelect from '../../components/Elements/Form/FormSelect';

const Resources = () => {
    const [searchParams] = useSearchParams()
    const [data, setData] = useState([]);
    const queryPillar = searchParams.get('pillar') && searchParams.get('pillar') !== '' ? searchParams.get('pillar') : 'all'
    const [pillar, setPillar] = useState(queryPillar)

    const pillars = pillarMap()

    let options = [{
        value: 'all',
        label: 'All'
    }]

    Object.keys(pillars).forEach(pillar => {
        options.push({
            value: pillar,
            label: pillars[pillar].title
        })
    })

    const getResources = async () => {
        const response = await requestClient().get(`/api/v2/resources/overview?pillar=${pillar}`)

            let articles = {}
        
            response.data.data.map(article => {
                if(!(article.content_type in articles )){
                    articles[article.content_type] = {
                        title: resourceMapper(article.content_type).title,
                        link: `/resources/${article.content_type}`,
                        articles:  []
                    }
                }
          
                articles[article.content_type].articles.push(article)
            })
            
            setData(articles);
    }

    useEffect(() => {
        async function getData() {
            getResources()
        }
        getData();
     }, []);

     useEffect(() => {
        async function updatePillar() {
            await getResources()
        }
        updatePillar()
    }, [pillar]);

    return (data ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between gap-4 flex-col lg:flex-row border-b pb-8 mb-8'>
                        <h1>Resources</h1>
                        <FormSelect value={pillar} options={options} onChange={(event) => setPillar(event.target.value)} />
                    </div>
                    <ArticleLists data={data} />
                 </div>
            </section>
        </main>
    : null);
};

export default Resources;
