import { useEffect } from "react";
import { GoogleIcon } from "../Icons";

const GoogleTranslate = () => {
  useEffect(() => {
    const scriptId = "google-translate-script";

    // Define the global callback BEFORE the script loads
    window.googleTranslateElementInit = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          { pageLanguage: "en" },
          "google_translate_element"
        );
      }
    };

    // Check if script is already loaded to prevent duplicates
    if (!document.querySelector(`#${scriptId}`)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"; // Use Google's `cb`
      script.async = true;
      document.body.appendChild(script);
    } else {
      // If script is already loaded, call the function immediately
      if (window.google && window.google.translate) {
        window.googleTranslateElementInit();
      }
    }
  }, []);

  return (
    <div className="bg-white flex items-center py-1 px-2">
      <GoogleIcon className="w-[28px] mr-1" />
      <div id="google_translate_element"></div>
    </div>
  );
};

export default GoogleTranslate;