import React from 'react';
import { Link } from 'react-router-dom';

import { resourceUrl } from '../../../utils/resourceMapper';
import Image from '../../../components/Elements/Image';
import PillarPill from '../../../components/Elements/PillarPill';
import { pillarMapper } from '../../../utils/pillarMapper';

const ArticleCardWorkshop = ({ item, selectedGoal }) => {
    const url = resourceUrl(item)
    let imageUrl = 'https://place-hold.it/300x200'

    if ('thumbnail' in item && item.thumbnail) {
        imageUrl = item.thumbnail
    }
    else if ('image' in item && item.image) {
        imageUrl = item.image
    }

    return (
        <div className={`round lg:rounded-xl lg:flex lg:flex-col ${selectedGoal === item.id ? 'outline-2 outline-offset-8 outline outline-purple': ''}`}>
            <Link to={url} className='relative block'>
                {'pillar' in item ? (<PillarPill pillar={pillarMapper(item.pillar)} className={"absolute top-2 right-2"} />) : null}
                <Image
                    src={imageUrl} alt={item.title} className="w-full rounded-xl lg:rounded-2xl aspect-video object-cover"
                />
            </Link>
            <div className='mt-4 mb-6 lg:flex lg:grow lg:flex-col'>
                <Link to={url}>
                    <h3 className="h4 mb-4">{item.title}</h3>
                    <p className="text-sm leading-none whitespace-pre-line">{item.description}</p>
                </Link>
            </div>
            <div className='flex gap-2 flex-col'>
                <Link to={url} className={`btn ${selectedGoal === item.id ? 'btn-purple' : 'btn-invert'}`}>
                    {selectedGoal === item.id ? 'Continue' : 'View'}
                </Link>
            </div>
        </div>
    );
};

export default ArticleCardWorkshop;