import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import requestClient from "../../utils/requestClient"; // Ensure requestClient is properly configured
import { XMarkIcon } from "@heroicons/react/24/outline"; // Import Hero Icons
import { motion, AnimatePresence } from "framer-motion"; 
import Cookies from 'js-cookie'


const Chatbot = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false); // 🟡 Tracks initial loading state

  const messagesEndRef = useRef(null); // 🟡 Reference to last message
  const scrollContainerRef = useRef(null); // 🟡 Reference to scroll container

  // 🟡 Expose `toggle` method via `useImperativeHandle`
  useImperativeHandle(ref, () => ({
    toggle: () => setIsVisible((prev) => !prev),
  }));

  // 🔹 Load messages only when the chatbot becomes visible
  useEffect(() => {
    if (isVisible) {
      loadMessages();
    }
  }, [isVisible]); // Runs when `isVisible` changes

  const loadMessages = async () => {
    setLoadingMessages(true); // Start loading state
    try {
      const response = await requestClient().get("/api/v2/assistant");
      if (response.data && response.data.messages) {
        setMessages(response.data.messages);
        scrollToBottom(); // Ensure messages start at the bottom
      }
    } catch (error) {
      console.error("Error loading messages:", error);
    } finally {
      setLoadingMessages(false); // Stop loading state
    }
  };

  const sendMessage = async () => {
    if (!input.trim()) return;
  
    const userMessage = { role: "user", content: input };
    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setLoading(true);
    setWaiting(true);
    setTimeout(() => scrollToBottom(), 50);
  
    try {
      const csrftoken = Cookies.get('csrfmiddlewaretoken')

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v2/assistant`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ message: input }),
        credentials: "include" // ⬅️ send session cookie
      });
  
      if (!response.ok) {
        throw new Error("Failed to stream response");
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
  
      let assistantMessage = { role: "assistant", content: "" };
      setMessages((prev) => [...prev, assistantMessage]);
  
      // Text is coming
      setWaiting(false);

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value, { stream: true });
  
        // Assuming your server sends: data: {"content": "Hello"}\n\n
        const lines = chunk.split("\n").filter(Boolean);
        for (const line of lines) {
          if (line.startsWith("data: ")) {
            const data = JSON.parse(line.replace("data: ", ""));
            if (data.content) {
              assistantMessage.content += data.content;
  
              // Update last assistant message in the UI
              setMessages((prev) => {
                const updated = [...prev];
                updated[updated.length - 1] = { ...assistantMessage };
                return updated;
              });
  
              scrollToBottom();
            }
          }
        }
      }
  
    } catch (err) {
      console.error("Streaming error:", err);
    } finally {
      setLoading(false);
    }
  };

  const clearChat = async () => {
    try {
      await requestClient().delete("/api/v2/assistant");
      setMessages([]); // Reset UI messages
    } catch (error) {
      console.error("Error clearing chat:", error);
    }
  };

    // 🟡 Function to scroll to the bottom of the chat
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

  if (!isVisible) return null; // ✅ Ensuring hooks are always called before conditionals

  return (
    <motion.div
    initial={{ opacity: 0, x: 50, y: 50, scale: 0.95 }} // 🟡 Starts from bottom-right corner
    animate={{ opacity: 1, x: 0, y: 0, scale: 1 }} // 🟢 Moves to normal position
    exit={{ opacity: 0, x: 50, y: 50, scale: 0.95 }} // 🔴 Exits back to bottom-right
    transition={{ duration: 0.3, ease: "easeOut" }} // 🎭 Smooth motion
        className="fixed z-10 bottom-4 left-4 right-4 w-auto mx-auto bg-white drop-shadow-lg rounded-xl p-5 lg:w-full lg:max-w-lg lg:left-auto"
    >
    <div className="flex justify-between items-center mb-4">
        <h4 className="text-lg font-semibold">OptiMe AI Assistant</h4>
        <button onClick={() => setIsVisible(false)}>
          <XMarkIcon className="w-5 h-5" />
        </button>
      </div>

      <div ref={scrollContainerRef} className="h-64 overflow-y-auto border rounded-lg p-3 bg-gray-50">
        {/* 🟡 Show loading indicator while fetching messages */}
        {loadingMessages ? (
          <p className="text-sm text-center">Loading messages...</p>
        ) : messages.length === 0 ? (
          <p className="text-sm text-center">No messages yet.</p>
        ) : (
          messages.map((msg, index) => (
            <>
            <motion.div 
                key={index}
                initial={{ opacity: 0, x: msg.role === "user" ? 30 : -30 }} // 🟡 Slide from side
                animate={{ opacity: 1, x: 0 }} 
                transition={{ duration: 0.3 }} // 🟢 Staggered effect
                className={`mb-3 ${msg.role === "user" ? "text-right ml-8" : "text-left mr-8"}`}
            >
                <span className={`px-3 py-2 rounded-lg inline-block ${msg.role === "user" ? "bg-purple-light-alt text-purple" : "bg-purple text-white"}`}>
                    {msg.content}
                </span>
            </motion.div>
            </>
          ))
        )}
        {waiting && (<motion.div 
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }} 
            transition={{ duration: 0.3 }}
            className={`mb-3 text-left`}
        >
            <span className={`px-3 py-2 rounded-lg inline-block bg-purple text-white`}>...</span>
        </motion.div>)}
        {/* 🟡 Invisible div to scroll to */}
        <div ref={messagesEndRef}></div>
      </div>

      <div className="mt-4 flex gap-2 flex-col">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && sendMessage()}
          className="flex-1 p-2 border rounded-lg outline-none"
          placeholder="Type a message..."
        />
        <button onClick={sendMessage} className="btn btn-purple" disabled={loading}>
          {loading ? "Sending..." : "Send"}
        </button>
        <button onClick={clearChat} className="btn btn-invert w-full">
          Clear Chat
        </button>
      </div>
    </motion.div>
  );
});

export default Chatbot;
