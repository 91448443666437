import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import { resourceMapper } from '../../utils/resourceMapper';
import Image from '../../components/Elements/Image';
import Video from '../../components/Elements/Video';
import FavouriteButton from '../../components/Elements/FavouriteButton';
import RatingButton from '../../components/Elements/RatingButton';
import ArticleBreadcrumb from '../../components/Elements/ArticleBreadcrumb';
import FormCheckbox from '../../components/Elements/Form/FormCheckbox';
import Pill from '../../components/Elements/Pill';
import RelatedArticles from '../../components/Sections/RelatedArticles';
import { CurrentUser } from '../../App';
import SuccessMessage from '../../components/Elements/SuccessMessage';
import { pillarMapper } from '../../utils/pillarMapper';
import PillarPill from '../../components/Elements/PillarPill';

const Article = () => {
  const {user, reloadUser} = useContext(CurrentUser)
  const { type, id } = useParams();
  const resource = resourceMapper(type)

  const [article, setArticle] = useState([]);
  const [currentUserTasks, setCurrentUserTasks] = useState(null);
  const [taskMap, setTaskMap] = useState({});
  const [complete, setComplete] = useState(false);

  const loadUserTasks = () => {
    let userTasks = {}
    let map = {}
    let isComplete = true

    user.user.active_goal.tasks.forEach(task => {
      userTasks[task.id] = task
      map[task.task.id] = task.id
    })

    Object.keys(userTasks).forEach(key => {
      if (userTasks[key].is_complete === false) {
        isComplete = false
      }
    })

    setTaskMap(map)
    setCurrentUserTasks(userTasks)
    setComplete(isComplete)
  }

  useEffect(() => {
    async function getArticle() {
      const response = await requestClient().get(`/api/v2/${type}/${id}`)

      setArticle(response.data.data);

      if('goal' in response.data.data.content){
        if (user.user.active_goal && user.user.active_goal.goal.video.id === response.data.data.content.id) {
          loadUserTasks()
        }
      }
    }
    getArticle();
  }, []);

  const updateTask = async (taskId, completed) => {
    const response = await requestClient().post(`/api/v2/goals/tasks/${taskId}/`, {
      is_complete: completed
    })

    let newTasks = { ...currentUserTasks };
    newTasks[taskId].is_complete = completed
    setCurrentUserTasks(newTasks)

    let isComplete = true
    Object.keys(newTasks).forEach(key => {
      if (newTasks[key].is_complete === false) {
        isComplete = false
      }
    })
    setComplete(isComplete)
  }

  const selectWorkshop = async (goalId) => {
      const response = await requestClient().post(`/api/v2/goals/`, {
          id: goalId
      })

      reloadUser();

      // Can't seem to avoid this
      window.location.reload()
  }

  return (article.content ?
    <main>
      <article className='bg-white'>
        <div className='container py-8 lg:py-12'>
          <div className='flex items-center justify-between mb-8'>
            <ArticleBreadcrumb article={article.content} />
            <Link to={`/resources?type=${article.content.content_type}`} className="flex items-center lg:hidden">← RESOURCES</Link>
            {'pillar' in article.content ? (<PillarPill pillar={pillarMapper(article.content.pillar)} size='lg' />) : null}
          </div>
                    

          <div className='max-w-[768px] mx-auto'>
            <h1 className='mb-8'>{article.content.title}</h1>
            {article.content.video ? (
              <Video src={article.content.video} className="aspect-video w-full rounded-xl lg:rounded-2xl" />
            ) : (<Image src={article.content.image} className="aspect-video w-full rounded-xl lg:rounded-2xl" />)}

            {article.content.pdf_document ? (<a href={article.content.pdf_document} target='_blank' className="btn btn-purple mt-4 block lg:inline-block">Download Content</a>) : null}

            <section>
              {'description' in article.content ? (
                <div className="mt-8 prose whitespace-pre-line">
                  <p>{article.content.description}</p>
                </div>
              ) : null}

              {'content' in article.content ? (
                <div className="mt-8 prose whitespace-pre-line">
                  <p>{article.content.content}</p>
                </div>
              ) : null}
            </section>

            {article.content.goal ? (
              <div className={`border border-2 rounded-2xl p-8 mt-8 shadow-lg ${currentUserTasks !== null ? 'border-purple' : 'border-gray'}`}>
              {complete ? <SuccessMessage title="Well done" text="You have completed this workshop!" className="mb-4" /> : null}
                <div className='flex items-center justify-between mb-6'>
                  <h3 className='h4'>{currentUserTasks ? 'Complete Your Tasks' : 'Workshop Tasks'}</h3>
                  <Pill title="50 pts" className={`${currentUserTasks !== null ? 'bg-purple text-white' : 'text-black'}`} />
                </div>
                <ul>
                  {article.content.goal.tasks.map((task) => (
                    <li className="flex gap-4 justify-between mb-4" key={task.id}>
                      {currentUserTasks !== null? 
                      <FormCheckbox label={task.title} value={currentUserTasks[taskMap[task.id]].is_complete} onChange={(event) => { updateTask(taskMap[task.id], !currentUserTasks[taskMap[task.id]].is_complete) }} /> 
                      : <FormCheckbox label={task.title} disabled={true} />}
                    </li>
                  ))}
                </ul>
                {currentUserTasks === null && !complete ? (
                  <button onClick={() => selectWorkshop(article.content.id)} className="mt-6 btn btn-purple block w-full">Select Workshop</button>
                  ) : null}
                {currentUserTasks !== null && !complete ? (
                  <span className="mt-6 btn bg-grey text-black block w-full">Workshop Selected</span>
                  ) : null}
                {complete ? <Link to="/workshops" className="mt-6 btn btn-purple block">Browse Workshops</Link> : null}
              </div>
            ) : null}

            {resource.rating || resource.favourite ?
              <div className="mt-8 py-4 border-t border-b flex items-center justify-between">
                {resource.rating ? <RatingButton article={article.content} /> : null}
                {resource.favourite ? <FavouriteButton article={article.content} favourited={article.favourited} /> : null}
              </div> : null}
          </div>
        </div>
      </article>
      <RelatedArticles article={article} />
    </main>
    : null);
};

export default Article;
