import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from "react-router-dom";

import requestClient from '../../utils/requestClient';
import { pillarMap } from '../../utils/pillarMapper';
import { resourceMapper } from '../../utils/resourceMapper';
import ArticleCard from '../../components/Elements/Cards/ArticleCard';
import FormSelect from '../../components/Elements/Form/FormSelect';
import Pagination from '../../components/Elements/Pagination';

const ResourceList = () => {
    const [searchParams] = useSearchParams()
    const { type } = useParams();

    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const queryPillar = searchParams.get('pillar') && searchParams.get('pillar') !== '' ? searchParams.get('pillar') : 'all'
    const [pillar, setPillar] = useState(queryPillar)

    const pillars = pillarMap()

    let options = [{
        value: 'all',
        label: 'All'
    }]

    Object.keys(pillars).forEach(pillar => {
        options.push({
            value: pillar,
            label: pillars[pillar].title
        })
    })

    const getResources = async () => {
        const response = await requestClient().get(`/api/v2/resources/?type=${type}&page=${currentPage}&pillar=${pillar}`)
        setData(response.data)
    }

    useEffect(() => {
        async function getData() {
            await getResources()
        }
        getData();
    }, []);

    useEffect(() => {
        async function paginate() {
            await getResources()
        }
        paginate()
    }, [currentPage]);

    useEffect(() => {
        async function updatePillar() {
            await getResources()
        }
        updatePillar()
    }, [pillar]);

    return (data ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between gap-4 flex-col lg:flex-row border-b pb-8 mb-8'>
                        <h1>{resourceMapper(type).title}</h1>
                        <FormSelect value={pillar} options={options} onChange={(event) => setPillar(event.target.value)} />
                    </div>
                    <div className='grid gap-8 lg:grid-cols-3 mb-8'>
                        {data.data.map((article, index) => (
                            <ArticleCard key={index} item={article} />
                        ))}
                    </div>
                    <Pagination currentPage={currentPage} data={data.data} pagination={data.meta.paginator} callback={setCurrentPage} />
                </div>
            </section>
        </main>
        : null);
};

export default ResourceList;
