import requestClient from "./requestClient";
import Cookies from "js-cookie";

export const loadCsrfToken = async () => {
  try {
    const response = await requestClient().get("/api/v2/csrf");
    const token = response.data?.csrftoken;

    if (token) {
      Cookies.set("csrfmiddlewaretoken", token, { sameSite: "Strict" });

      const input = document.getElementById("crsf");
      if (input) {
        input.setAttribute("value", token);
      }
    }
  } catch (error) {
    console.error("Failed to load CSRF token:", error);
  }
};
